
@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&family=Open+Sans:ital,wght@0,400;1,600&family=Playfair+Display:wght@400;600&display=swap');


.App {
  text-align: center;
  font-family: 'MedievalSharp', cursive;
  color:lightgrey;

}

* {
  box-sizing: border-box;
}

body {
  overflow:hidden;
}

.overlay {
  opacity:0.85;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.background {
  z-index: -1;
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/assets/background/background.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 40% 40%;
}